import { useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { useAction, useTranslate } from "@helpers/hooks";
import { Divider, Icon, List, NewFeature } from "@components/common";
import { getNavigationData, isNewFeature } from "@utils/functions";
import classNames from "classnames";
import "./Sidebar.scss";

export default function Sidebar() {
  const { t } = useTranslate(),
    { pathname } = useLocation(),
    { totalCountFavorites } = useSelector((state) => state.program),
    { profile } = useSelector((state) => state.profile),
    { fetchFavoritesTotalCount, fetchApplicationsTotalCount } = useAction();

  const isStaff = profile?.is_staff;

  useEffect(() => {
    fetchFavoritesTotalCount({ is_shortlisted: true });
    fetchApplicationsTotalCount({ is_applying: true });
  }, []);

  return (
    <aside className={"sidebar"}>
      <nav className={"sidebar__nav"}>
        <List
          className={"sidebar__list"}
          items={getNavigationData(t, totalCountFavorites, isNewFeature, isStaff)}
          renderItem={({ id, route, icon, name, isNew, isDivider }) => {
            const shouldHighlight =
              route.includes("blog") ||
              route.includes("scholarships") ||
              route.includes("mock")
                ? pathname.includes(route)
                : pathname === route;
            return (
              <li key={id} className={"sidebar__item"}>
                <NavLink
                  to={route}
                  className={`sidebar__link ${icon}`}
                  activeClassName={classNames({
                    "sidebar__link--selected": shouldHighlight,
                  })}
                >
                  <Icon
                    className={classNames({ "icon--white": shouldHighlight })}
                    name={icon}
                  />
                  <p
                    className={classNames("sidebar__text", {
                      "sidebar__text--selected": shouldHighlight,
                    })}
                  >
                    {name}
                  </p>
                  {isNew && <NewFeature isSelected={shouldHighlight} />}
                </NavLink>

                {isDivider && <Divider className={"sidebar__divider"} />}
              </li>
            );
          }}
        />
      </nav>
    </aside>
  );
}
